import { HttpClient } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfirmEventType,
  ConfirmationService,
  Confirmation,
  MessageService,
} from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { PermissionsComponent } from 'src/app/shared/components/permissions/permissions.component';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-admin-upsert-users',
  templateUrl: './admin-upsert-users.component.html',
  styleUrls: ['./admin-upsert-users.component.scss'],
})
export class AdminUpsertUsersComponent {
  @ViewChild('ap') ap?: PermissionsComponent;
  @ViewChild('ad') ad?: Confirmation;

  user?: any;
  loading?: boolean;
  loadingClients?: boolean;
  deleteLoading?: boolean;
  roles?: any;
  is_client: boolean = false;

  form = new FormGroup({
    // eslint-disable-next-line @typescript-eslint/unbound-method
    firstName: new FormControl(null, Validators.required),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    lastName: new FormControl(null, Validators.required),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    email: new FormControl(null, [Validators.required, Validators.email]),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    role: new FormControl(null, Validators.required),
    client: new FormControl(null),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    password: new FormControl(null, Validators.required),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    permissions: new FormControl(null, Validators.required),
    records: new FormControl<any>(null),
  });

  errors: { [key: string]: string | boolean } = {};

  @Input() owner_id?: string;
  @Input() embed: boolean = false;
  @Input() id: string = '';

  records: any;
  assignedRecords: any[] = [];
  selectedRecord: any;
  inserting: boolean = false;

  constructor(
    private readonly http: HttpClient,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private toastService: MessageService,
  ) {}

  async ngAfterViewInit() {
    await this.http
      .get(environment.api + 'users/roles')
      .toPromise()
      .then((data: any) => {
        this.roles = data;
        this.form.get('role')?.setValue(this.roles[0]);
      });

    //const params = await this.route.snapshot.queryParams;

    //if (params['embedded']) {
    //  this.embedded = params['embedded'];
    //}

    //if(params['id']){
    if (this.id !== '' && this.id !== undefined) {
      this.inserting = false;
      await this.loadUser(this.id);
    }
    //}
    else {
      this.inserting = true;
      await this.ap?.getDefaultsByRole(this.roles[0]?.id);
    }

    //this.owner_id = params['owner_id'];
  }

  async loadUser(id: string) {
    this.loading = true;
    const user = (await this.http
      .get(environment.api + 'user/' + id)
      .toPromise()) as any;
    this.user = user;
    this.form.get('password')?.clearValidators();
    this.form.get('password')?.updateValueAndValidity();

    this.is_client = user.is_client;


    this.form.patchValue({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: this.roles.find((role: any) => role.id === user?.role),
      client: user.client,
      permissions: user?.permissions,
      records: user?.records,
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    this.assignedRecords = user?.userRecords.map((r: any) => r.record);

    console.log(this.assignedRecords);

    const result = await lastValueFrom(
      this.http.get<any>(
        environment.api +
          'expedientes/byuser/' +
          this.owner_id +
          '/' +
          this.user.id,
      ),
    );

    this.records = result?.data || [];

    console.log(this.records);

    console.log(user?.permissions);
    await this.ap?.update(user?.permissions);
    this.loading = false;
  }
  async roleChange(event: any) {
    if (event?.value && event.originalEvent && !this.loading) {
      await this.ap?.getDefaultsByRole(event.value.id);
    }
  }
  permissionsChange(event: any) {
    this.form.get('permissions')?.setValue(event);
  }
  async save() {
    if (this.form.valid) {
      this.loading = true;

      //this.user['userRecords'] = this.assignedRecords;

      console.log(this.user);

      const values: any = this.form.value;
      values['userRecords'] = this.assignedRecords;
      const permissions = values.permissions;
      const mappedPermissions: { scope: string; type: string }[] = [];
      for (const scope in permissions) {
        for (const type in permissions[scope]) {
          if (permissions[scope][type]) {
            mappedPermissions.push({ scope, type });
          }
        }
      }
      values.permissions = mappedPermissions;
      values.role = values.role.id;
      console.log(values);

      try {
        const url = this.user
          ? this.http.put<any>(environment.api + 'user/' + this.user.id, values)
          : this.http.post<any>(environment.api + 'user', values);

        const data = await lastValueFrom(url);

        console.log(data);

        if (data) {
          console.log(data);

          if (this.user == null) {
            const tipoUser: any = await lastValueFrom(
              this.http.get(environment.api + 'owners/type/' + this.owner_id),
            );

            console.log(tipoUser);

            if (tipoUser?.type == 'client') {
              const result = await lastValueFrom(
                this.http.post(environment.api + 'owners/client/user', {
                  client: this.owner_id,
                  user: data.id,
                }),
              );
              console.log(result);
            } else {
              const result = await lastValueFrom(
                this.http.post(environment.api + 'owners/provider/user', {
                  client: this.owner_id,
                  user: data.id,
                }),
              );
              console.log(result);
            }
          }

          this.location.back();
        }

        this.loading = false;
      } catch (e: any) {
        console.log(e);

        if (e.error.message === 'EMAIL_ALREADY_EXIST') {
          this.form.get('email')?.setErrors({ emailAlreadyExist: true });
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'El correo ya existe',
          });
          UtilsService.markGroupControlsAsDirty(this.form);
        }

        this.loading = false;
      }
    } else {
      this.loading = false;
      UtilsService.markGroupControlsAsDirty(this.form);
    }
  }

  delete() {
    this.confirmationService.confirm({
      key: 'delete',
      accept: async () => {
        this.deleteLoading = true;
        await this.http
          .delete(environment.api + 'user/' + this.user.id)
          .toPromise()
          .then((_data: any) => {
            this.location.back();
          })
          .catch((error) => {
            console.log(error);
          });
        this.deleteLoading = false;
      },
      reject: (_type: ConfirmEventType) => {},
    });
  }
  addRecord() {
    this.confirmationService.confirm({
      key: 'addRecord',
    });
  }

  recordSelected(e: any) {
    console.log(e);
    this.ad?.reject?.();
    //filter duplicates
    if (
      this.form
        .get('records')
        ?.value?.find((record: any) => record?.id === e?.id) !== undefined
    )
      return;

    this.form
      .get('records')
      ?.setValue([...(this.form.get('records')?.value || []), e]);
  }

  getBack() {
    this.location.back();
  }

  dragStart(record: any) {
    this.selectedRecord = record;
  }

  drop() {
    if (this.selectedRecord) {
      if (
        this.assignedRecords.find((r: any) => r.id === this.selectedRecord.id)
      ) {
        return;
      }
      this.assignedRecords.push(this.selectedRecord);

      this.records = this.records.filter(
        (r: any) => r.id !== this.selectedRecord.id,
      );

      this.selectedRecord = null;

      console.log(this.assignedRecords);
    }
  }

  dropBack() {
    if (this.selectedRecord) {
      if (this.records.find((r: any) => r.id === this.selectedRecord.id)) {
        return;
      }

      this.records.push(this.selectedRecord);

      this.assignedRecords = this.assignedRecords.filter(
        (r: any) => r.id !== this.selectedRecord.id,
      );

      this.selectedRecord = null;

      console.log(this.records);
    }
  }

  dragEnd() {
    this.selectedRecord = null;
  }
}

<div class="m-10">
  <!-- Title -->
  <div class="flex item-center w-full mt-14 mb-4">
      <h2 class="flex-grow text-xl font-bold text-am-primary-navy-100">{{'admin.modify_linked_contract' | translate}}</h2>
  </div>

  <div class="flex max-md:flex-col max-md:gap-4 justify-between">
    <div class="notification__title flex flex-col gap-1">
        <h2 class="text-am-primary-navy-100 font-bold text-am-2xl">Ayto. {{locality?.name}}</h2>
        <p class="text-am-secondary-navy-80 text-base ">{{locality?.address || ('contracts.general.no_address' | translate)}}</p>
        <p class="text-am-secondary-navy-80 text-base">{{locality?.phone || ('contracts.general.no_phone' | translate)}}</p>
        <a class="text-base text-am-primary-turquoise-100 font-semibold"
        target="_blank"
        [href]="locality?.web">{{locality?.web}}</a>
    </div>
  </div>

  <!-- Div para condiciones base -->
  <div class="rounded-md border border-emerald-500 mt-4">

    <h2 class="p-4">{{ 'contracts.newContractLinked.limits' | translate }}</h2>
    <p-table [value]="baseConditions">

      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'contracts.newContractLinked.table.from' | translate }}</th>
          <th>{{ 'contracts.newContractLinked.table.to' | translate }}</th>
          <th>{{ 'contracts.newContractLinked.table.price' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td>{{ data.lowLimit }}</td>
          <td>{{ data.highLimit }}</td>
          <td>{{ data.price | currency:'':'' }} €</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <!-- Div para condiciones extra -->
  <div class="rounded-md border border-emerald-500 mt-4">
    <div *ngFor="let condition of extraConditions; ; let first = first; let i = index">
      <div class="grid grid-cols-4 p-4 gap-4" [ngClass]="{ 'border-t border-emerald-200': !first }" [formGroup]="form">
        <p-selectButton [options]="stateOptions" [formControlName]="i + 1" [allowEmpty]="false" optionLabel="label" optionValue="value"></p-selectButton>
        <h2 class="self-center">{{ condition.title }}</h2>
        @if (form.get(i + 1 + '').value) {
          <h2 class="self-center justify-self-end font-bold">{{condition.extraType == 1 ? getPriceForCondition(condition) : ''}}</h2>
        }
        @else {
          <h2 class="self-center justify-self-end font-semibold text-emerald-500">{{ condition.extraType == 1 ? ('contracts.general.request_price' | translate) : '-' }}</h2>
        }

        <h2 class="self-center justify-self-center font-bold">{{ condition.extraType == 0 ? (condition.price | currency:'':'') + '€'  : '' }}</h2>
      </div>
    </div>
  </div>

  <!-- Botonera -->
  <div class="rounded-md border border-emerald-500 mt-4 p-4">
    <h2> {{ 'contracts.newContractLinked.message1' | translate}}</h2>
    <h2 class="mb-8">{{ 'contracts.newContractLinked.message2' | translate}}</h2>
    <p-button pRipple [label]="'contracts.newContractLinked.send' | translate" [style]="{'minWidth':'160px'}" iconPos="left" size="small" (click)="sendToAproval()"></p-button>
  </div>
</div>

<div class="flex justify-between mt-4 mb-4 items-start">
    <span class="p-input-icon-right h-max">
        <i class="pi pi-search"></i>
        <input type="text" pInputText class="p-inputtext-sm w-100" [placeholder]="'inspections.searchRecords' | translate" (input)="dt.filterGlobal($event.target.value, 'contains')" />
    </span>
</div>

  <p-table #dt
    [value]="data"
    [paginator]="true"
    [rows]="10"
    [totalRecords]="totalRecords"
    [lazy]="true"
    (onLazyLoad)="load($event)"
    [totalRecords]="totalRecords"
    dataKey="id"
    [loading]="loading"
    sortField="reference"
    sortOrder="1"
    [rowHover]="true"
    [showCurrentPageReport]="true"
    [globalFilterFields]="['reference', 'locality', 'locality.province', 'manager', 'status']"
    currentPageReportTemplate="{currentPage} de {totalPages}"
  >

    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="reference">{{'records.recordNum' | translate}} <p-sortIcon field="reference"></p-sortIcon></th>
            <!-- <th pSortableColumn="client">{{'records.client' | translate}} <p-sortIcon field="client"></p-sortIcon></th> -->
            <th pSortableColumn="locality_name">{{'records.locality' | translate}} <p-sortIcon field="locality.name"></p-sortIcon></th>
            <th pSortableColumn="locality_province">{{'records.province' | translate}} <p-sortIcon field="locality.province"></p-sortIcon></th>
            <th class="sm:hidden" pSortableColumn="manager">{{'records.manager' | translate}} <p-sortIcon field="manager"></p-sortIcon></th>
            <th pSortableColumn="status">{{'records.status' | translate}} <p-sortIcon field="status"></p-sortIcon></th>

            <th style="width: 4rem"></th>
            <th style="width: 4rem"></th>
            <th style="width: 4rem"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr class="cursor-pointer" id="{{item.id}}" (click)="selected(item)">
            <td class="font-semibold">{{ item.reference }}</td>
            <!-- <td>{{ item.client?.businessName || '-' }}</td> -->
            <td>{{ item.locality?.name || '-' }}</td>
            <td>{{ item.locality?.province || '-' }}</td>
            <td class="sm:hidden">{{ item.manager_id?.email }}</td>
            <td>
              @switch (item.status) {
                @case ('ACTIVE') { {{'records.statusvalue.active' | translate}} }
                @case ('INACTIVE') { {{'records.statusvalue.inactive' | translate}}}
                @case ('PENDING') { {{'records.statusvalue.pending' | translate}}}
                @case ('CLOSED') { {{'records.statusvalue.closed' | translate}}}

              }
            </td>
            <td>
              @if (canEdit && item.status === 'ACTIVE') {
                <button pButton pRipple *ngIf="isProvider && !embedded" type="button" icon="pi pi-exclamation-triangle" class="p-button-text p-button-rounded p-button-plain "
                (click)="deactivate($event, item)"></button>

              }
            </td>
            <td>
              @if (canEdit && item.status === 'PENDING') {
                <button pButton pRipple *ngIf="isProvider && !embedded" [hidden]="!item.deleteable || !canDelete" type="button" icon="pi pi-trash" class="p-button-text p-button-rounded p-button-plain "
                (click)="delete($event, item)"></button>
              }
            </td>
            <td>
                <button pButton pRipple type="button" [hidden]="!canView" icon="pi pi-chevron-right" class="p-button-text p-button-rounded p-button-plain "
                (click)="selected(item)"></button>
            </td>
        </tr>
    </ng-template>


    <ng-template pTemplate="emptymessage" let-columns let-frozen="frozen">
        <tr>
            <td [attr.colspan]="8"><div class="text-center p-10">No hay resultados</div></td>
        </tr>
    </ng-template>

    <ng-template pTemplate="sorticon" let-number>
        <i *ngIf="number == 0" class="pi pi-sort" style="opacity:0.2"></i>
        <i *ngIf="number == 1" class="pi pi-sort-down"></i>
        <i *ngIf="number == -1" class="pi pi-sort-up"></i>
    </ng-template>

</p-table>


<p-confirmDialog #dialog [style]="{ width: '50vw' }">
  <p-toast></p-toast>
  <ng-template pTemplate="footer">
    <div  class="flex flex-row mt-4">
      <p-button type="button" [label]="'admin.cancel' | translate" size="small" [outlined]="true" (click)="dialog.reject()"></p-button>
      <p-button type="button" [label]="'Ejecutar' | translate" size="small" severity="danger" (click)="dialog.accept()"></p-button>
    </div>
  </ng-template>
</p-confirmDialog>

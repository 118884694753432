import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  PermissionScope,
  PermissionType,
  User,
  UserService,
} from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { DOCUMENT, Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { saveAs } from 'file-saver';
import { EmpresaRetiradaDTO } from '../domain/empresa-retirada.dto';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-retiradas-form',
  templateUrl: './retiradas-form.component.html',
  styleUrls: ['./retiradas-form.component.css'],
})
export class RetiradasFormComponent implements OnInit {
  form: FormGroup = new FormGroup({
    id: new FormControl(''),
    inspection: new FormGroup({
      id: new FormControl(''),
      date: new FormControl(''),
      catastralReference: new FormControl(''),
      address: new FormControl(''),
      year: new FormControl(''),
    }),
    empresaRetirada: new FormControl(null),
    coste_desamiantado: new FormControl(0),
    coste_transporte: new FormControl(0),
    coste_entrega: new FormControl(0),
    subvencion: new FormControl(0),
    total: new FormControl(0),
    num_rera: new FormControl(''),
    notas: new FormControl(''),
    completado: new FormControl(false),
    // Docs
    doc_memoria: new FormControl([]),
    doc_actividad: new FormControl([]),
    doc_vertederos: new FormControl([]),
    doc_otros: new FormControl([]),
    doc_formacion: new FormControl([]),
    doc_memoria_toadd: new FormControl([]),
    doc_actividad_toadd: new FormControl([]),
    doc_vertederos_toadd: new FormControl([]),
    doc_otros_toadd: new FormControl([]),
    doc_formacion_toadd: new FormControl([]),
    doc_memoria_todelete: new FormControl([]),
    doc_actividad_todelete: new FormControl([]),
    doc_vertederos_todelete: new FormControl([]),
    doc_otros_todelete: new FormControl([]),
    doc_formacion_todelete: new FormControl([]),
  });

  retiradaOptionsES = [
    { id: true, name: 'Si' },
    { id: false, name: 'No' },
  ];

  retiradaOptionsEN = [
    { id: true, name: 'Yes' },
    { id: false, name: 'No' },
  ];

  user?: User;
  canCreate: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  canView: boolean = false;
  canViewAll: boolean = false;
  isClient: boolean = false;
  isProvider: boolean = false;
  isOrganization: boolean = false;

  owner_id: string = '';

  empresasRetirada: EmpresaRetiradaDTO[] = [];

  loading: boolean = false;

  enlaceCatastro: string = '';
  imagenCatastro: string = '';

  baseUri: string =
    'https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCListaBienes.aspx?rc1=';
  baseUriImg: string =
    'https://ovc.catastro.meh.es/OVCServWeb/OVCWcfLibres/OVCFotoFachada.svc/RecuperarFotoFachadaGet?ReferenciaCatastral=';
  rc1: string = '';
  rc2: string = '';

  selectedLanguage: string = 'es';

  constructor(
    private route: ActivatedRoute,
    private userSvc: UserService,
    private http: HttpClient,
    private location: Location,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private messageService: MessageService,
  ) {}

  private clientPermissions() {
    this.canEdit = false;
    this.canDelete = false;
    this.canView = true;
    this.canViewAll = true;
  }

  private providerPermissions() {
    this.canCreate =
      this.user?.hasPermission(
        PermissionScope.WITHDRAWALS,
        PermissionType.CREATE,
      ) || false;
    this.canEdit =
      this.user?.hasPermission(
        PermissionScope.WITHDRAWALS,
        PermissionType.EDIT,
      ) || false;
    this.canDelete =
      this.user?.hasPermission(
        PermissionScope.WITHDRAWALS,
        PermissionType.DELETE,
      ) || false;
    this.canView =
      this.user?.hasPermission(
        PermissionScope.WITHDRAWALS,
        PermissionType.VIEW,
      ) || false;
    this.canViewAll =
      this.user?.hasPermission(
        PermissionScope.WITHDRAWALS,
        PermissionType.VIEW_ALL,
      ) || false;
  }

  private organizationPermissions() {
    this.canEdit = false;
    this.canDelete = false;
    this.canView =
      this.user?.hasPermission(
        PermissionScope.WITHDRAWALS,
        PermissionType.VIEW,
      ) || false;
    this.canViewAll =
      this.user?.hasPermission(
        PermissionScope.WITHDRAWALS,
        PermissionType.VIEW_ALL,
      ) || false;
  }

  async ngOnInit() {
    this.loading = true;

    //this.addLocation();
    this.user = this.userSvc.getUser();

    console.log(this.translate);

    this.selectedLanguage = this.translate.defaultLang;

    this.isClient = this.user?.is_client || false;
    this.isProvider = this.user?.is_provider || false;
    this.isOrganization = this.user?.is_organization || false;

    if (this.isClient) {
      this.clientPermissions();
    }

    if (this.isProvider) {
      this.providerPermissions();
      this.owner_id = this.user?.owner_id || '';
    }

    if (this.isOrganization) {
      this.organizationPermissions();
    }

    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.canEdit ? this.form.enable : this.form.disable();

    const snapshot = this.route.snapshot;

    await this.load(
      snapshot.queryParams['recordId'],
      snapshot.queryParams['agId'],
      snapshot.queryParams['id'],
    );
  }

  setForm(data: any) {
    console.log(data);

    this.form.get('id')?.setValue(data.id);

    this.form.get('coste_desamiantado')?.setValue(data.coste_desamiantado);
    this.form.get('coste_transporte')?.setValue(data.coste_transporte);
    this.form.get('coste_entrega')?.setValue(data.coste_entrega);
    this.form.get('subvencion')?.setValue(data.subvencion);

    this.form.get('empresaRetirada')?.setValue(data.empresaRetirada);
    this.form.get('num_rera')?.setValue(data.num_rera);
    this.form.get('notas')?.setValue(data.notas);
    this.form.get('completado')?.setValue(data.completado);

    this.form.get('doc_memoria')?.setValue(data.doc_memoria || []);
    this.form.get('doc_actividad')?.setValue(data.doc_actividad || []);
    this.form.get('doc_vertederos')?.setValue(data.doc_vertederos || []);
    this.form.get('doc_otros')?.setValue(data.doc_otros || []);
    this.form.get('doc_formacion')?.setValue(data.doc_formacion || []);

    this.form.get('doc_memoria_toadd')?.setValue([]);
    this.form.get('doc_actividad_toadd')?.setValue([]);
    this.form.get('doc_vertederos_toadd')?.setValue([]);
    this.form.get('doc_otros_toadd')?.setValue([]);
    this.form.get('doc_formacion_toadd')?.setValue([]);

    this.form.get('doc_memoria_todelete')?.setValue([]);
    this.form.get('doc_actividad_todelete')?.setValue([]);
    this.form.get('doc_vertederos_todelete')?.setValue([]);
    this.form.get('doc_otros_todelete')?.setValue([]);
    this.form.get('doc_formacion_todelete')?.setValue([]);

    this.calcularTotal();

    const fg = this.form.get('inspection') as FormGroup;

    fg.get('id')?.setValue(data.inspection.id);
    fg.get('date')?.setValue(new Date(data.inspection.date));
    fg.get('catastralReference')?.setValue(data.inspection.catastralReference);
    fg.get('address')?.setValue(data.inspection.address);
    fg.get('year')?.setValue(data.inspection.year);

    fg.get('id')?.disable();
    fg.get('date')?.disable();
    fg.get('catastralReference')?.disable();
    fg.get('address')?.disable();
    fg.get('year')?.disable();

    this.form.get('total')?.disable();

    if (
      fg.get('catastralReference')?.value != null &&
      fg.get('catastralReference')?.value != ''
    ) {
      this.rc1 = fg.get('catastralReference')?.value.substring(0, 7);
      this.rc2 = fg.get('catastralReference')?.value.substring(7, 14);

      this.enlaceCatastro = this.baseUri + this.rc1 + '&rc2=' + this.rc2;
      this.imagenCatastro =
        this.baseUriImg + fg.get('catastralReference')?.value;
    }
  }

  save() {
    console.log(this.form.value);

    if (this.form.valid) {
      this.http
        .put(
          environment.api + 'retiradas/' + this.form.get('id')?.value,
          this.form.value,
        )
        .subscribe((data: any) => {
          console.log(data);
          this.location.back();
        });
    }
  }

  async load(recordId: string, agId: string, id: string) {
    const data: any = await lastValueFrom(
      this.http.get(
        environment.api + `retiradas/byid/${recordId}/${agId}/${id}`,
      ),
    );

    this.empresasRetirada = await lastValueFrom(
      this.http.get<EmpresaRetiradaDTO[]>(
        environment.api + 'retiradas/companies',
      ),
    );

    this.setForm(data);

    console.log(this.form);

    this.loading = false;
  }

  documentAdded(e: any, field: string) {
    console.log('Adding document');
    console.log(e);

    const pictures = this.form.get(`${field}_toadd`)?.value;
    pictures.push(e);

    this.form.get(`${field}_toadd`)?.patchValue(pictures);

    console.log(this.form);
  }

  documentRemoved(e: any, field: string) {
    console.log(e);
    console.log(field);

    // Puede ser que este recien añadida
    const pictures: any[] = this.form.get(`${field}`)?.value;

    const result = pictures.filter((item: any) => item == e);

    if (result.length > 0) {
      // en cuyo caso la sacamos de la lista de añadidas
      const result = pictures.filter((item: any) => item != e);
      this.form.get(`${field}_toadd`)?.patchValue(result);
      this.form.get(`${field}`)?.patchValue(result);
    } else {
      // o que ya estuviera previamente añadida
      const pictures2: any[] = this.form.get(`${field}`)?.value;
      const result2 = [
        ...result,
        pictures.filter((item: any) => item.path == e.path),
      ];

      if (result2.length > 0) {
        // en cuyo caso la insertamos en la lista de las de borrar
        const result = pictures2.filter((item: any) => item.path != e.path);

        console.log(result);

        this.form.get(`${field}`)?.patchValue(result);

        const result3: any[] = this.form.get(`${field}_todelete`)?.value;
        result3.push(e);

        this.form.get(`${field}_delete`)?.patchValue(result3);

        console.log(this.form);
      }

      console.log(this.form);
    }

    //console.log((this.form.get('locations') as FormArray)?.at(index));
  }

  calcularTotal() {
    const coste_desamiantado: number = parseFloat(
      this.form.get('coste_desamiantado')?.value,
    );
    const coste_transporte: number = parseFloat(
      this.form.get('coste_transporte')?.value,
    );
    const coste_entrega: number = parseFloat(
      this.form.get('coste_entrega')?.value,
    );
    const subvencion: number = parseFloat(this.form.get('subvencion')?.value);

    const baseImponible: number =
      coste_desamiantado + coste_transporte + coste_entrega;
    const totalSubvencion: number = (baseImponible * subvencion) / 100;

    this.form.get('total')?.setValue(baseImponible - totalSubvencion);
  }

  async downloadDocument() {
    const url =
      'assets/templates/V01_Amiantic_Memoria proyecto retirada amianto.pdf';

    this.http.get(url, { responseType: 'blob' }).subscribe((res: any) => {
      saveAs(res, 'V01_Amiantic_Memoria proyecto retirada amianto.pdf');
    });
  }

  selectEmpresa(e: any) {
    console.log(e);
    this.form.get('num_rera')?.setValue(e.value.numeroRera);
  }
}
